const data = [
    {
        "id":"1",
        "question":"My Counsellor listened to my problem.",
        "selected":""
      },
      {
        "id":"2",
        "question":"My Counsellor Understood my point of view",
        "selected":""
      },
      {
        "id":"3",
        "question":"My Counselling session began at the schedule time",
        "selected":""
      },
      {
        "id":"4",
        "question":"My Counselling session began at the schedule time",
        "selected":""
      },
      {
        "id":"5",
        "question":"I am feeling better after Counselling session",
        "selected":""
      },
      {
        "id":"6",
        "question":"I am satisfied with my settion overall.",
        "selected":""
      }
]

export default data