import React from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
// import { BarChart } from '@mui/x-charts/BarChart';

export default function studentGraph() {
  return (
    <>
    <Navbar/>
    <Sidebar/>
    <div>
        <div>
        {/* <BarChart
      xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
      series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
      width={500}
      height={300}
    /> */}
        </div>
    </div>
    </>
  )
}
