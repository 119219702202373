export const LOGIN_ERR = 'LOG_IN_ERROR';
export const LOGIN_SUCCESS = 'LOG_IN_SUCESS';
export const LOG_OUT = 'LOGOUT';
export const INFORMATION_COMPLETED = 'INFORMATION_FILLED';
export const RE_LOGIN = 'RE_LOGIN';

export const USERDATA_ERR = 'USERDATA_ERR';
export const USERDATA_SUCCESS = 'USERDATA_SUCCESS';

export const INCRMENT_QUESTION = 'INCRMENT_QUESTION'
export const DECREMENT_QUESTION = 'DECREMENT_QUESTION'
export const INCRMENT_COUNTER = 'INCRMENT_COUNTER'
export const DECRMENT_COUNTER = 'DECRMENT_COUNTER'
export const SET_COUNTER_ZERO = 'SET_COUNTER_ZERO'
export const UPDATE_QUESTION = 'UPDATE_QUESTION'
export const UPDATE_ANSWER = 'UPDATE_ANSWER'
export const SET_QUESTION = 'SET_QUESTION'
export const COUNT_QUESTION_ATTEMPTS = 'COUNT_QUESTION_ATTEMPTS'